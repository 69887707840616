// assets
import { IconKey, IconUserStar, IconCash, IconShoppingCart, IconCreditCardFilled, IconSettingsCog } from '@tabler/icons-react';

// constant
const icons = {
  IconKey,
  IconCash,
  IconUserStar,
  IconShoppingCart,
  IconCreditCardFilled,
  IconSettingsCog
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const hrms = {
  id: 'hrms',
  title: 'HRMS',
  type: 'group',

  children: [
    {
      id: 'staff',
      title: 'Produit',
      type: 'item',
      url: '/hrms/staff',
      icon: icons.IconUserStar
    },
    {
      id: 'payroll',
      title: 'Paille',
      type: 'item',
      url: '/hrms/payroll',
      icon: icons.IconCash
    },
    {
      id: 'suppliers',
      title: 'Service',
      type: 'item',
      url: '/hrms/supplier',
      icon: icons.IconShoppingCart
    },
    {
      id: 'Creditors',
      title: 'Crediteure',
      type: 'item',
      url: '/hrms/creditors',
      icon: icons.IconCreditCardFilled
    },
    {
      id: 'Setting',
      title: 'Facture',
      type: 'item',
      url: '/hrms/settings',
      icon: icons.IconSettingsCog
    }
  ]
};

export default hrms;
